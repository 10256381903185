<template>
  <standard-page title="Process Registries">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
      <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
      <span>New</span>
    </template>
    <template v-slot:content>
      <div class="az-content-label mg-b-5">New Registry</div>
      <div class="mg-b-20" v-html="$DEFINITIONS.provenance.newRegistry"></div>
      <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
        <div class="row row-xs">
          <div class="col-md-10">
            <input class="form-control" placeholder="Enter Registry name" type="text" v-model="registry.name">
          </div>
          <div class="col-md mg-t-10 mg-md-t-0">
            <button @click="createRegistry" class="btn rounded btn-az-primary btn-block">Submit</button>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import EventBus from "@/event-bus";

export default {
  name: "NewRegistry",
  data() {
    return {
      registry: {
        name: null
      },
    };
  },
  methods: {
    async createRegistry() {
      if (this.registry.name) {
        try {
          EventBus.$emit('openLoader');
          await this.$provenance.post('provenance/registries', this.registry);
          this.$toastr.s("Registry Successfully created!", 'Success');
        } catch (e) {
          this.$toastr.e("Registry creation failed", 'Error');
        } finally {
          this.registry.name = null;
          EventBus.$emit('closeLoader');
          await this.$router.push({ name: 'provenances-registries' });
        }
      } else {
        this.$toastr.e("Please provide a name first!", 'Failed');
      }
    },
  }
}
</script>

<style scoped>
</style>